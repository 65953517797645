<template>
	<section class="fullpage fullpage-footer __fp-light fp-footer">
		<div class="fp-content container">
			<h2 class="sr-only">공지사항 / SNS</h2>
			<div class="display-flex">
				<!-- 공지사항 -->
				<article class="fp-footer-article">
					<div class="fp-footer-article__header">
						<h3 class="fp-footer-article__title">
							<router-link to="/notice"> 공지사항</router-link>
						</h3>
					</div>
					<div class="fp-footer-article__content">
						<ul class="simple-notice-list">
							<li v-for="(item, idx) in notices" :key="idx">
								<!-- <a :href="'/notice/' + item.ntcId + '/view'" class="simple-notice-list__title">{{ item.ntcTit }}</a> -->
								<router-link :to="'/notice/' + item.ntcId + '/view'" class="simple-notice-list__title">{{ item.ntcTit }}</router-link>
								<span class="simple-notice-list__date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
							</li>
						</ul>
					</div>
				</article>
				<!-- //공지사항 -->
				<article class="fp-footer-article">
					<div class="fp-footer-article__header">
						<h3 class="fp-footer-article__title">SNS</h3>
					</div>
					<div class="fp-footer-article__content">
						<ul class="fp-footer-sns">
							<li>
								<a href="https://blog.naver.com/modulong" target="_blank" title="새창 열림" class="fp-footer-sns__item">
									<i class="icon-footer-sns icon-fp-blog"></i>
									<span class="sr-only">네이버블로그</span>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/portalvaluebuy/" target="_blank" title="새창 열림" class="fp-footer-sns__item">
									<i class="icon-footer-sns icon-fp-insta"></i>
									<span class="sr-only">인스타그램</span>
								</a>
							</li>
							<li>
								<a href="https://www.youtube.com/c/%EA%B0%80%EC%B9%98%EC%82%BD%EC%8B%9C%EB%8B%A4TV" target="_blank" title="새창 열림" class="fp-footer-sns__item">
									<i class="icon-footer-sns icon-fp-youtube"></i>
									<span class="sr-only">유튜브</span>
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/portalvaluebuy" target="_blank" title="새창 열림" class="fp-footer-sns__item">
									<i class="icon-footer-sns icon-fp-facebook"></i>
									<span class="sr-only">페이스북</span>
								</a>
							</li>
							<li>
								<a href="http://pf.kakao.com/_SrNtT/chat" target="_blank" title="새창 열림" class="fp-footer-sns__item">
									<i class="icon-footer-sns icon-fp-kakao"></i>
									<span class="sr-only">카카오 챗봇</span>
								</a>
							</li>
						</ul>
					</div>
				</article>
			</div>
		</div>
		<main-footer />
	</section>
</template>

<script>
import { dateToDateFormat, getCheckItems, getIsNew, getItems, lengthCheck, timestampToDateFormat, ymdToFormat } from '@/assets/js/utils';
import { ACT_GET_NOTICE2_LIST } from '@/store/_act_consts';

import MainFooter from '@/views/layout/footer/MainFooter';

export default {
	name: 'MainFullpageFooter',
	components: { MainFooter },
	data: () => ({
		notices: [], //하단 공지사항 목록
	}),
	computed: {},
	created() {},
	mounted() {
		this.getNotices();
	},
	methods: {
		ymdToFormat,
		dateToDateFormat,
		timestampToDateFormat,

		getIsNew(timestamp, now, baseDay) {
			return getIsNew(timestamp, now, baseDay);
		},
		getNotices() {
			this.$store
				.dispatch(`notice2/${ACT_GET_NOTICE2_LIST}`, {
					pageSize: 5,
				})
				.then((res) => {
					this.notices = this.notices.concat(getItems(res));
				})
				.catch((e) => {
					console.error(e);
				});
		},
	},
};
</script>
