<template>
	<!-- <section v-show="ntfctnList.length > 0" id="MainIndexNoti" class="main-noti-wrap"> -->
	<section id="MainIndexNoti" class="main-noti-wrap">
		<div class="container">
			<h3 class="main-noti-title">
				<i class="icon-main-noti"></i>
				알림마당
			</h3>
			<!-- <swiper ref="swiperIndex" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
				<swiper-slide v-for="(item, index) in swiperList" :key="index">
					<router-link :to="item.link">{{ item.title }}</router-link>
					<router-link :to="item.link">{{ item.title }}</router-link>
				</swiper-slide>
			</swiper> -->
			<swiper ref="swiperIndex" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
				<swiper-slide v-for="(item, index) in ntfctnList" :key="index">
					<router-link :to="item[0].link">{{ item[0].title }}</router-link>
					<router-link :to="item[1].link">{{ item[1].title }}</router-link>
				</swiper-slide>
			</swiper>
			<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_LIVE_VIDEO_LIST, ACT_GET_SENTCONT_NTFCTN_LIST } from '@/store/_act_consts';

import SwiperControls from '@/components/common/SwiperControls';

import { DateFormat, getMinusDay } from '@/assets/js/DateUtils';
import { getCheckItems, dateToDateFormat } from '@/assets/js/utils';

export default {
	name: 'MainIndexNoti',
	components: { SwiperControls },
	props: {
		// searchData: Function,
	},
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		stepIndex: 0,
		// swiperList: [
		// 	{ title: '[판:소리] EP.6 사업의 시작과 끝, 사업계획서 판로TV VOD 판로TV VOD', link: '/broadcast' },
		// 	{ title: '전통시장 신용카드 소득공제율 80%로 상향 조정 전통시장 신용카드 소득공제율 80%로 상향 조정', link: '/broadcast' },
		// ],
		swiperOption: {
			loop: true,
			direction: 'vertical',
			touchRatio: 0.3, //드래그 금지
			slidesPerView: 1,
			spaceBetween: 0,
			pagination: {
				el: '#MainIndexNoti .swiper-pagination',
				type: 'fraction',
			},
			autoplay: {
				delay: 5000,
				reverseDirection: true,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '#MainIndexNoti .swiper-button-next',
				prevEl: '#MainIndexNoti .swiper-button-prev',
			},
		},

		vodList: [],
		sentContList: [],
		ENUMERATION_LIMIT: 5,
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo', 'shortsBrcTyp']),

		ntfctnList() {
			const minLength = Math.min(this.vodList.length, this.sentContList.length);
			const ENUMERATION_THRESHOLD = Math.min(this.ENUMERATION_LIMIT, minLength);

			let nList = [];
			Array.from({ length: ENUMERATION_THRESHOLD }).forEach((_, idx) => {
				nList.push([
					{
						title: this.vodList[idx].BRC_TIT,
						link: `/broadcast/${this.vodList[idx].BRC_INFO_NO}/view`,
					},
					{
						title: this.sentContList[idx].sentContTit,
						link: `/sentcont/${this.sentContList[idx].sentContCtgrDcd}/view/${this.sentContList[idx].sentContId}`,
					},
				]);
			});
			// console.log("nList:", nList);
			return nList;
		},
	},
	watch: {},
	created() { },
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiperIndex.$swiper;
		});

		this.getVODList();
		this.getSentContList();
	},
	beforeDestroy() { },
	methods: {
		// event ======================================================================
		onSwiperChange() {
			this.activeIndex = Number(this.thisSwiper.realIndex);
		},

		// custom fn ======================================================================

		// promise ======================================================================
		async getVODList() {
			const now = new Date();
			const params = {
				pageIndex: 1,
				pageSize: this.ENUMERATION_LIMIT,
				brcTyp: 2,
				programCd: '',
				brcStsCd: '20',
				// regBgngDt: dateToDateFormat(getMinusDay(now, 30), DateFormat),
				// regEndDt: dateToDateFormat(now, DateFormat),
			};
			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, params)
				.then((res) => {
					// console.log('vodList:', res.data.LIST);
					this.vodList = res.data.LIST;
				})
				.catch(console.error);
		},

		async getSentContList() {
			const params = {
				pageNo: 1,
				pageSize: this.ENUMERATION_LIMIT,
			};
			this.$store
				.dispatch(`sentcont/${ACT_GET_SENTCONT_NTFCTN_LIST}`, params)
				.then((res) => {
					// console.log('sentContList:', res);
					this.sentContList = getCheckItems(res);
				})
				.catch(console.error);
		},
	},
};
</script>
