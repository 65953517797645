<template>
	<section class="fullpage fp-live __fp-light">
		<div class="fp-content">
			<div class="fp-title-wrap">
				<h2 class="fp-title">라이브홍보</h2>
				<div class="fp-title-right">
					<router-link to="live/schedule" class="boradcast-button-cal"><i class="icon-tv"></i><span>편성표보기</span><i class="icon-arrow-right"></i></router-link>
					<router-link to="live/list" class="boradcast-button"><span>더보기</span><i class="icon-arrow-right"></i></router-link>
				</div>
			</div>
			<p class="fp-subtitle">실시간 소통 온라인 상품 판매</p>

			<div class="fp-live-panel">
				<swiper class="swiper" ref="swiperLive" :options="swiperOptionLive">
					<swiper-slide v-for="(brcItem, idx) in brcInfoList" :key="idx">
						<live-video-card :brcInfo="brcItem" :key="`${brcItem.BRC_INFO_NO}_MAIN_SALT_${idx}`" />
					</swiper-slide>
				</swiper>
				<div class="swiper-controls">
					<div class="swiper-pagination-container">
						<div class="swiper-pagination" slot="pagination"></div>
					</div>
				</div>
				<div class="swiper-buttons-container">
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { mapGetters } from 'vuex';
import { ACT_DELETE_PROG_SMSUSER, ACT_GET_PROG_BANNER_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_GET_MSS_SPRT_BIZ_LIST, ACT_GET_SUPT_BIZ_LIST, ACT_INSERT_PROG_SMSUSER, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_LIVE_COMMERCE_LIST } from '@/store/_act_consts';
import LiveCommCard from '@/components/live/LiveCommCard';
import LiveVideoCard from '@/components/live/LiveVideoCard.vue';

export default {
	name: 'MainLivePr',
	components: { LiveVideoCard, LiveCommCard },
	data: () => ({
		brcInfoList: [],
		swiperOptionLive: {
			loop: false,
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 0,
			pagination: {
				el: '.fp-live .swiper-pagination',
				type: 'fraction',
			},
			navigation: {
				nextEl: '.fp-live .swiper-button-next',
				prevEl: '.fp-live .swiper-button-prev',
			},
			breakpoints: {
				200: {
					spaceBetween: 0,
					slidesPerView: 1,
					slidesPerGroup: 1,
				},
				500: {
					spaceBetween: 0,
					slidesPerView: 2,
					slidesPerGroup: 2,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 3,
					slidesPerGroup: 3,
				},
				1000: {
					spaceBetween: 0,
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
			},
		},
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},
	created() {},
	mounted() {
		// this.getLiveBrcList();
	},
	methods: {
		liveSwiperInit(size) {
			const swiperLive = this.$refs.swiperLive.$swiper;
			swiperLive.slideTo(0);
			//   if(this.isMobile){
			//     swiperLive.slideTo(1);
			//   }else{
			//     swiperLive.slideTo(4);
			//   }
		},
		clickMoveLive(e) {
			const code = e.currentTarget.getAttribute('data-value');
			this.$router.push({ name: 'LiveView', params: { brcInfoNo: code } });
		},
		// 라이브 커머스 메인 페이지 리스트 처리
		getLiveBrcList() {
			const stsCds = ['07', '08', '09', '10', '12', '20'];
			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					pageSize: 12,
					pageIndex: 1,
					brcStsCd: stsCds.toString(),
					sortCd: '01',
				})
				.then((res) => {
					this.brcInfoList = res.data.LIST;
					this.liveSwiperInit();
				})
				.catch((e) => {
					this.brcInfoList = [];
				});
		},
	},
};
</script>
