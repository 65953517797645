<template>
	<section class="fullpage fullpage-partner fp-partner __fp-light">
		<div class="fp-content container">
			<div class="fp-fade-down">
				<h2 class="fp-title">소상공인 온라인 진출 지원</h2>
				<p class="fp-subtitle">130개 파트너사가 여러분과 함께합니다!</p>

				<p class="fp-desc">각 분야의 전문가들과 함께 소상공인 여러분의 판로진출을 지원하겠습니다</p>
			</div>

			<div class="fp-partner-scroll fp-normal-scroll">
				<div class="fp-partner-scroll__video">
					<video muted id="partnerVideoPC" class="pc">
						<source src="~@/assets/img/renew/partner_pc.mp4" type="video/mp4" />
					</video>
					<video muted id="partnerVideoMO" class="mo">
						<source src="~@/assets/img/renew/partner_mo.mp4" type="video/mp4" />
					</video>
				</div>
				<div class="fp-partner-scroll__inner">
					<div class="fp-partner-scroll__img-handle">
						<div class="fp-partner-scroll__img">
							<img class="pc" src="~@/assets/img/renew/partner_pc.png" alt="온라인 진출 파트너 목록" />
							<img class="mo" src="~@/assets/img/renew/partner_mo.png" alt="온라인 진출 파트너 목록" />
						</div>
					</div>
				</div>
				<div class="fp-partner-scroll__bar">
					<input type="range" min="0" max="100" value="0" class="slider" id="imageRange" @change="scrollVertically" />
				</div>
			</div>
		</div>
	</section>
</template>
<script>
// import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';
export default {
	name: 'FullPagePartner',
	props: {},
	components: {},
	computed: {},
	data: () => ({
		isVisible: false, // 리소스가 로드 되면 함수 실행을 멈출지 말지 정하는 변수
	}),
	created() {},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('scroll', this.onScroll);
			document.querySelector('.fp-partner-scroll__inner').addEventListener('scroll', this.onPartnerScroll);
		});
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		//window 의 이벤트는 해제가 필요하지만 document는 이미 element가 제거된 상태라 이벤트 해제 에러가 발생하는듯 함. 주석처리.
		//document.querySelector('.fp-partner-scroll__inner').removeEventListener('scroll', this.onPartnerScroll);
	},
	methods: {
		// 스크롤 정의
		onScroll(e) {
			const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
			if (!this.isVisible && this.checkVisible('.fullpage-partner') && !this.checkInvisible('.fullpage-partner')) {
				document.querySelector('html').classList.add('__fullpage-partner');
				this.isVisible = true;
				this.toggleVideoPlay();
				document.getElementById('imageRange').value = 0;
				setTimeout(() => {
					document.querySelector('.fp-partner-scroll__inner').scrollTo(0, 0);
				}, 1000);
			} else if (this.checkInvisible('.fullpage-partner')) {
				document.querySelector('html').classList.remove('__fullpage-partner');
				this.isVisible = false;
				this.toggleVideoPause();
			}
			// if (!this.isVisible && this.checkVisible('.fullpage-partner')) {
			// 	document.querySelector('html').classList.add('__fullpage-partner');
			// 	this.toggleVideoPlay();
			// 	document.getElementById('imageRange').value = 0;
			// 	document.querySelector('.fp-partner-scroll__img').style.marginTop = 0;
			// 	setTimeout(() => {
			// 		this.isVisible = true;
			// 	}, 1000);
			// } else if (this.isVisible) {
			// 	document.querySelector('html').classList.remove('__fullpage-partner');
			// 	document.querySelector('html').classList.add('__fullpage-partner--visible');
			// }
			// 만일 리소스가 로드가 되면 더이상 이벤트 스크립트가 있을 필요가 없으니 삭제
			// this.isVisible && window.removeEventListener('scroll', this.onScroll);
		},
		// 파트너 영역의 스크롤
		onPartnerScroll(e) {
			const scrollHeight = e.target.scrollHeight;
			const scrollTop = e.target.scrollTop;
			const clientHeight = e.target.clientHeight;
			let scrollValue = parseInt((scrollTop / (scrollHeight - clientHeight)) * 100);
			document.getElementById('imageRange').value = scrollValue;
		},
		// 가로 스크롤 슬라이더 컨트롤
		scrollVertically() {
			const value = document.getElementById('imageRange').value;
			const scrollHeight = document.querySelector('.fp-partner-scroll__inner').scrollHeight;
			const clientHeight = document.querySelector('.fp-partner-scroll__inner').clientHeight;

			let scrollTo = parseInt((scrollHeight - clientHeight) * (value / 100));
			document.querySelector('.fp-partner-scroll__inner').scrollTo(0, scrollTo);
		},
		// 요소가 화면에 보여지는지 체크
		checkVisible(element, check = 'visible') {
			const { clientHeight, scrollHeight, scrollTop } = document.documentElement;
			const y = document.querySelector(element).offsetTop;
			const elementHeight = document.querySelector(element).clientHeight;

			// 반드시 요소가 화면에 보여야 할경우
			if (check == 'visible') return y < clientHeight + scrollTop && y > scrollTop - elementHeight;

			// 화면에 안보여도 요소가 위에만 있으면 (페이지를 로드할때 스크롤이 밑으로 내려가 요소를 지나쳐 버릴경우)
			if (check == 'above') return y < clientHeight + scrollTop;
		},
		// 요소가 화면에서 사라졌는지 체크
		checkInvisible(element) {
			const { clientHeight, scrollHeight, scrollTop } = document.documentElement;
			const y = document.querySelector(element).offsetTop;
			const elementHeight = document.querySelector(element).clientHeight;

			return y >= clientHeight + scrollTop || y <= scrollTop - elementHeight;
		},
		checkVideoPlaying() {
			const videoPc = document.getElementById('partnerVideoPC');
			// const videoMo = document.getElementById('partnerVideoMO');
			return videoPc.currentTime > 0 && !videoPc.paused && !videoPc.ended && videoPc.readyState > videoPc.HAVE_CURRENT_DATA;
		},
		// 비디오 재생
		toggleVideoPlay() {
			const videoPc = document.getElementById('partnerVideoPC');
			const videoMo = document.getElementById('partnerVideoMO');
			try {
				if (!this.checkVideoPlaying()) {
					// setTimeout(() => {
					videoPc.currentTime = 0;
					videoPc.play();
					videoMo.currentTime = 0;
					videoMo.play();
					// }, 1000);
				}
			} catch (error) {
				console.error(error);
			}
		},
		// 비디오 중지
		toggleVideoPause() {
			const videoPc = document.getElementById('partnerVideoPC');
			const videoMo = document.getElementById('partnerVideoMO');
			try {
				videoPc.pause();
				videoMo.pause();
			} catch (error) {
				console.error(error);
			}
		},
	},

	destroyed() {},
};
</script>
