<template>
	<section class="fullpage fp-digital __fp-light">
		<div class="fp-content">
			<h2 class="fp-title">온라인 진출 지원사례</h2>
			<p class="fp-subtitle">
				대한민국 디지털 소상공인 10만명 만들기<br />
				<span class="hidden-mobile">사례를 공유합니다</span>
				<span class="visible-mobile">소상공인 성공사례를 확인하세요</span>
			</p>

			<div class="fp-digital-panels">
				<swiper class="swiper" ref="swiperDigital" :options="swiperOptionDigital">
					<swiper-slide v-for="(row, idx) in cmntyItems" :key="idx">
						<div class="fp-digital-panels-wrap">
							<a v-for="(item, rowIdx) in row" :key="rowIdx" :data-value="`${item.category}`" class="fp-digital-card">
								<span class="fp-digital-card__img">
									<picture>
										<source media="(max-width: 800px)" :srcset="require(`@/assets/img/renew/${item.moImg}`)" />
										<source media="(min-width: 801px)" :srcset="require(`@/assets/img/renew/${item.img}`)" />
										<img :src="require(`@/assets/img/renew/${item.img}`)" alt="" />
									</picture>
								</span>
								<div class="fp-digital-card__title">{{ item.title }}</div>
								<div class="fp-digital-card__tags">
									<span v-for="(tag, tagIdx) in item.tags" :key="tagIdx">
										<em>{{ tag }}</em>
									</span>
								</div>
							</a>
						</div>
					</swiper-slide>
				</swiper>
				<div class="swiper-controls">
					<button type="button" :class="isDigitalAutoplay ? 'swiper-button-play' : 'swiper-button-stop'" @click="toggleDigitalAutoplay()">
						<template v-if="isDigitalAutoplay">재생</template>
						<template v-else>중지</template>
					</button>

					<div class="swiper-pagination-container">
						<div class="swiper-pagination" slot="pagination"></div>
					</div>
				</div>
				<div class="swiper-buttons-container">
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'MainOnlineSuccess',
	data: () => ({
		comunCtgrDcd: '', //디지털 전환 카테고리 코드
		cmntyCtgrs: [], //온라인 진출 지원사례 카테고리 목록
		isDigitalAutoplay: false,
		cmntyItems: [
			[
				{
					title: '상품성 개선',
					category: '1007001',
					img: 'digital01.jpg',
					moImg: 'digital01_mo.jpg',
					tags: ['디자인/콘텐츠', '온라인 홍보 컨설팅', '밀키트 상품화', 'KC 인증 컨설팅'],
				},
				{
					title: '상세페이지 지원',
					category: '1007002',
					img: 'digital02.jpg',
					moImg: 'digital02_mo.jpg',
					tags: ['상세페이지 기획/제작', '온라인 입점지원'],
				},
				{
					title: '콘텐츠 제작 지원',
					category: '1007004',
					img: 'digital03.jpg',
					moImg: 'digital03_mo.jpg',
					tags: ['커머스활용형', 'IPTV제작·송출형'],
				},
				{
					title: 'V-커머스',
					category: '1007005',
					img: 'digital04.jpg',
					moImg: 'digital04_mo.jpg',
					tags: ['온라인 채널 영상 제작', '기획전 입점 지원'],
				},
			],
			[
				{
					title: '라이브 커머스',
					category: '1007006',
					img: 'digital05.jpg',
					moImg: 'digital05_mo.jpg',
					tags: ['대형 유통채널 라이브 송출', '스튜디오/방송장비 지원', '쇼호스트 지원'],
				},
				{
					title: '구독 경제',
					category: '1007007',
					img: 'digital06.jpg',
					moImg: 'digital06_mo.jpg',
					tags: ['민간플랫폼 입점지원', '기획전 입점 지원'],
				},
				{
					title: '온라인 기획전',
					category: '1007008',
					img: 'digital07.jpg',
					moImg: 'digital07_mo.jpg',
					tags: ['소상공인 기획전 운영', '다양한 프로모션 지원'],
				},
				{
					title: '해외쇼핑몰 입점지원',
					category: '1007009',
					img: 'digital08.jpg',
					moImg: 'digital08_mo.jpg',
					tags: ['해외플랫폼 입점교육/컨설팅', '상세페이지번역', '다양한 프로모션 지원'],
				},
			],
		], // 온라인 진출 지원사례 아이템 목록
		swiperOptionDigital: {
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			spaceBetween: 0,
			pagination: {
				el: '.fp-digital .swiper-pagination',
				type: 'fraction',
			},
			navigation: {
				nextEl: '.fp-digital .swiper-button-next',
				prevEl: '.fp-digital .swiper-button-prev',
			},
		},
	}),
	computed: {},
	created() {},
	beforeDestroy() {
		this.removeEventListener();
	},
	mounted() {
		// this.getCmntyCtgrList(); //디지털전환사례 카테고리
		// 판로TV, 온라인진출지원사례 클릭 이벤트 바인딩.
		setTimeout(() => {
			this.addEventListener();
		}, 1000);
		// 판로TV, 온라인진출지원사례 클릭 이벤트 바인딩.
	},
	methods: {
		// getCmntyCtgrList() {
		// 	//온라인 진출 지원사례 카테고리 리스트 불러오기
		// 	this.$store
		// 		.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {
		// 			dcd: '1007',
		// 			masterYn: 'N',
		// 		})
		// 		.then((res) => {
		// 			this.cmntyCtgrs = getCheckItems(res);
		// 			if (this.cmntyCtgrs) this.getCmntyItemList(this.cmntyCtgrs[0].dcd, 0);
		// 		});
		// },
		clickMoveDigit(e) {
			const code = e.currentTarget.getAttribute('data-value');
			this.$router.push('/community?ctgrCode=' + code);
		},
		addEventListener() {
			// 온라인 진출 지원사례 스와이프 클릭 이벤트 바인딩
			var digit = document.querySelectorAll('.fp-digital-card');
			for (var i = 0; i < digit.length; i++) {
				digit[i].addEventListener('click', this.clickMoveDigit);
			}
		},
		removeEventListener() {
			// 온라인 진출 지원사례 스와이프 클릭 이벤트 삭제
			var digit = document.querySelectorAll('.fp-digital-card');
			for (var i = 0; i < digit.length; i++) {
				digit[i].removeEventListener('click', this.clickMoveDigit);
			}
		},
		toggleDigitalAutoplay() {
			try {
				if (this.isDigitalAutoplay) {
					this.$refs.swiperDigital.$swiper.autoplay.start();
					this.isDigitalAutoplay = false;
				} else {
					this.$refs.swiperDigital.$swiper.autoplay.stop();
					this.isDigitalAutoplay = true;
				}
			} catch (error) {
				console.error(error);
			}
		},
		getCmntyItemList(comunCtgrDcd, idx) {
			this.$nextTick(() => {
				if (this.$refs.dropDown3) {
					let dropDown = this.$refs.dropDown3;
					let dropEl = document.querySelectorAll('.fp-digital .fp-mobile-control .dropdown-item');
					dropDown.selectDropdownItemByTarget(dropEl[idx]);
				}

				//온라인 진출 지원사례 카테고리 클릭 이벤트
				this.comunCtgrDcd = comunCtgrDcd;
				this.$store
					.dispatch(`community/${ACT_GET_SB_COMMUNITY_LIST}`, {
						comunCtgrDcd: this.comunCtgrDcd,
					})
					.then((res) => {
						this.cmntyItems = [];
						if (lengthCheck(res)) {
							this.cmntyItems = this.cmntyItems.concat(getItems(res));
							this.$refs.swiperDigital.$swiper.slideTo(0);
						} else {
							this.cmntyItems = [];
						}
						this.isLoading = false;
					})
					.catch((e) => {
						console.error(e);
						this.cmntyItems = [];
						this.isLoading = false;
					});
			});
		},
	},
};
</script>
