<template>
	<section v-if="fetaShow" class="fullpage fp-festa" :style="'background-image: url(' + require(`@/assets/img/renew/${festaList[festaActiveIndex].bgFileNm}`) + ');'">
		<div class="fp-content">
			<h2 class="fp-title">새소식</h2>
			<p class="fp-subtitle">2024년 5월 동행축제 개최</p>
			<div class="fp-festa-content">
				<swiper ref="swiperFesta" class="swiper" :options="swiperOptionFesta" @slideChange="onSwiperFestaChange">
					<swiper-slide v-for="(item, idx) in festaList" :key="idx">
						<div class="fp-festa-item">
							<span class="fp-festa-content__img">
								<img :src="require(`@/assets/img/renew/${item.imgFileNm}`)" :alt="item.title" />
							</span>
							<span class="fp-festa-content__right">
								<strong class="fp-festa-content__title">{{ item.title }}</strong>
								<span class="fp-festa-content__text">
									<em class="fp-festa-content__desc" v-html="item.desc"></em>
									<span class="fp-festa-content__slogan" v-if="item.slogan !== ''">
										<span class="text" v-html="item.slogan"></span>
									</span>
									<a v-if="item.url" :href="item.url" target="_blank" class="button-default is-large is-secondary is-rounded" title="새창열림">바로가기</a>
								</span>
							</span>
						</div>
						<div class="fp-festa-mobile">
							<span class="fp-festa-mobile__date">{{ item.pgDate }}</span>
							<span class="fp-festa-mobile__title">{{ item.pgTitle }}</span>
						</div>
					</swiper-slide>
				</swiper>
				<div class="swiper-controls">
					<button type="button" :class="isFestaAutoplay ? 'swiper-button-play' : 'swiper-button-stop'" @click="toggleFestaAutoplay()">
						<template v-if="isFestaAutoplay">재생</template>
						<template v-else>중지</template>
					</button>
					<div class="swiper-pagination-container">
						<div class="swiper-pagination" slot="pagination"></div>
					</div>
				</div>
				<div class="swiper-buttons-container">
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'MainFestaShow',
	data: () => ({
		fetaShow: false,
		isFestaAutoplay: false,
		swiperOptionFesta: {
			loop: true,
			// effect: 'fade',
			loopedSlides: 2,
			notNextTick: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
			},
			slidesPerView: 1,
			// loopedSlides: 0,
			spaceBetween: 0,
			touchRatio: 0.2,
			navigation: {
				nextEl: '.fp-festa-content .swiper-button-next',
				prevEl: '.fp-festa-content .swiper-button-prev',
			},
			festaPagiList: [
				{
					date: '2022.09.01 ~ 7',
					title: '7일간의 동행축제',
				},
				{
					date: '2022.12.16 ~ 25',
					title: '한겨울의 동행축제 윈·윈터 페스티벌',
				},
				{
					date: '2023.05.01 ~ 28',
					title: '5월 봄빛 동행축제',
				},
				{
					date: '2023.08.30 ~ 10.08',
					title: '9월 황금녘 동행축제',
				},
				{
					date: '2023.12.04 ~ 31',
					title: '12월 눈꽃 동행축제',
				},
				{
					date: '2024.05.01 ~ 28',
					title: '2024년 5월 동행축제',
				},
			],
			pagination: {
				el: '.fp-festa-content .swiper-pagination',
				// type: 'fraction',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"><em>' + this.params.festaPagiList[index].date + '</em><em>' + this.params.festaPagiList[index].title + '</em></span>';
				},
			},
			breakpoints: {
				200: {
					spaceBetween: 15,
					slidesPerView: 1.25,
					centeredSlides: true,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 1,
				},
			},
		},
		festaActiveIndex: 0,
		festaList: [
			{
				pgDate: '2022.09.01 ~ 7',
				pgTitle: '7일간의 동행축제',
				imgFileNm: 'festa4.png',
				bgFileNm: 'bg_festa4.jpg',
				title: '7일간의 동행축제',
				desc: '동행세일을 확대하여 다양한 국민 참여형 행사를 대규모로 개최하고, 온 국민이 참여하고 즐길 수 있는 상생 문화 캠페인으로 발돋움',
				slogan: '‘온 국민이 참여하는 축제의 장’',
			},
			{
				pgDate: '2022.12.16 ~ 25',
				pgTitle: '한겨울의 동행축제 윈·윈터 페스티벌',
				imgFileNm: 'festa3.png',
				bgFileNm: 'bg_festa3.jpg',
				title: '한 겨울의 동행축제 윈·윈터 페스티벌',
				desc: '소비자와 중소·소상공인, 유통사를 비롯해 도움의 손길이 필요한 분들이 겨울철(Winter) 함께 행복을 누릴 수(Win) 있도록 추진하는 따뜻한 ‘겨울 동행축제’',
				slogan: '‘모두가 이득 행복이 가득’',
			},
			{
				pgDate: '2023.05.01 ~ 28',
				pgTitle: '5월 봄빛 동행축제',
				imgFileNm: 'festa2.png',
				bgFileNm: 'bg_festa2.jpg',
				title: '5월 봄빛 동행축제',
				desc: '가정의 달과 중소기업 주간의 의미를 살려 국민과 기업 모두가 참여하는 교류·화합 축제의 장. 지역경제 활성화를 위해 지자체와 함께하는 전국적인 행사',
				slogan: '‘전국 방방곡곡 경기진작을 위한 온 국민,<br> 힘 모으기! 캠페인’',
			},
			{
				pgDate: '2023.08.30 ~ 10.08',
				pgTitle: '9월 황금녘 동행축제',
				imgFileNm: 'festa1.png',
				bgFileNm: 'bg_festa1.jpg',
				title: '9월 황금녘 동행축제',
				desc: '추석 명절에 맞춰 감사의 마음을 전하고 서로 응원하며 경제주체들의 기(氣)를 살리는 캠페인. 온국민이 참여해 전국 지역 경제에 활력 불어넣기!',
				slogan: '‘온 국민이 힘을 모아 우리 경제 기(氣)살리기 캠페인’',
			},
			{
				pgDate: '2023.12.04 ~ 31',
				pgTitle: '12월 눈꽃 동행축제',
				imgFileNm: 'festa5.png',
				bgFileNm: 'bg_festa5.jpg',
				title: '12월 눈꽃 동행축제',
				desc: '따뜻한 나눔과 함께 전국민 상생 소비 캠페인을 이어가는 2023년 마지막 동행축제,<br> 눈꽃 동행축제.',
				slogan: '온 국민, 힘 모아 온기(溫氣) 나눔',
			},
			{
				pgDate: '2024.05.01 ~ 28',
				pgTitle: '2024년 5월 동행축제',
				imgFileNm: 'festa6.png',
				bgFileNm: 'bg_festa6.jpg',
				title: '2024년 5월 동행축제',
				desc: '가족과 함께 고마운 분에게 마음을 전하고, <br>전국 중소기업•소상공인에게 활력을 불어넣는 동행축제',
				slogan: '‘살맛나는 행복쇼핑, 2024 동행축제’',
				url: 'http://k-shoppingfesta.org',
			},
		], //상단 프로그램 배너 목록
	}),
	computed: {},
	created() {
		// 동행축제
		const tagetDate = new Date('2024-06-01 00:00:00');
		const nowDate = new Date();
		if (nowDate < tagetDate) {
			this.fetaShow = true;
			// this.fullpagePagination = ['동행축제', '메인페이지', '판로TV', '시설 안내/예약', '라이브홍보', '온라인 진출 지원사례', '마이페이지', '지원사업 안내', '온라인 진출 파트너', '공지사항/SNS'];

			// Main.vue에서 사용하는 fullpagePagination을 변경
			this.fullpagePagination = ['동행축제', '메인페이지', '판로TV', '시설 안내/예약', '지원사업 안내', '공지사항/SNS'];
			this.$emit('fullpagePagination', this.fullpagePagination);
		}
		// 동행축제
	},
	mounted() {
		this.$nextTick(() => {
			// 동행축제
			if (this.fetaShow) {
				// 20231127 : 동행축제 수정
				document.querySelector('.fp-festa').classList.add('fp-swiper-fade');
				setTimeout(() => {
					document.querySelector('.fp-festa').classList.add('__active-fp');
				}, 2000);
				const swiperFesta = this.$refs.swiperFesta.$swiper;
				swiperFesta.slideTo(7);
				swiperFesta.autoplay.pause();
				swiperFesta.autoplay.start();
				// swiperFesta.slides[6].classList.remove('swiper-slide-custom-active');
				// setTimeout(() => {
				// 	// swiperFesta.slides[6].classList.add('swiper-slide-custom-active');
				// }, 3000);
			}
		});
	},
	methods: {
		toggleFestaAutoplay() {
			try {
				if (this.isFestaAutoplay) {
					this.$refs.swiperFesta.$swiper.autoplay.start();
					this.isFestaAutoplay = false;
				} else {
					this.$refs.swiperFesta.$swiper.autoplay.stop();
					this.isFestaAutoplay = true;
				}
			} catch (error) {
				console.error(error);
			}
		},
		onSwiperFestaChange() {
			const swiperFesta = this.$refs.swiperFesta.$swiper;
			this.festaActiveIndex = Number(swiperFesta.realIndex);
			// 20231127 : 동행축제 수정
			Object.keys(swiperFesta.slides).forEach((key) => {
				try {
					swiperFesta.slides[key].classList.remove('swiper-slide-custom-active');
				} catch (error) {}
			});
			setTimeout(function () {
				Object.keys(swiperFesta.slides).forEach((key) => {
					try {
						if (swiperFesta.slides[key].classList.contains('swiper-slide-active')) {
							swiperFesta.slides[key].classList.add('swiper-slide-custom-active');
						}
					} catch (error) {}
				});
			}, 1300);
			document.querySelector('.fp-festa').classList.remove('__active-fp');
			let timerId;
			clearTimeout(timerId);
			timerId = setTimeout(function () {
				document.querySelector('.fp-festa').classList.add('__active-fp');
			}, 1000);
		},
	},
};
</script>
