<template>
  <a :data-brcinfo="`${brcInfo.BRC_INFO_NO}`" :data-brcstscd="`${brcInfo.BRC_STS_CD}`" class="fp-live-card">
    <article class="live-card is-onair">
      <i
        class="video-badge"
        :class="{
          'video-badge-comingsoon': brcInfo.BRC_STS_CD === '07' || brcInfo.BRC_STS_CD === '08' || brcInfo.BRC_STS_CD === '09',
          'video-badge-live': brcInfo.BRC_STS_CD === '10',
          'video-badge-vod': brcInfo.BRC_STS_CD === '20',
        }"
      >
      </i>
      <div tabindex="0" class="pointer" :data-brcinfo="JSON.stringify(brcInfo)">
        <div class="live-video">
          <div v-if="brcInfo.BRC_STS_CD === '10' || brcInfo.BRC_STS_CD === '20'" class="video-watch">
            <i class="icon-watch"></i>
            <span class="text"> {{ brcInfo.INQ_CNT }} </span>
          </div>

          <div class="video-player">
            <img :src="brcInfo.BRC_RPRS_IMG_URL" alt="" @error="handleImgError" />
          </div>
          <button type="button" class="video-content pointer" :data-gdsid="brcInfo.GDS_ID" :data-gdspcomurl="brcInfo.GDS_PCOM_URL" :data-brcinfono="brcInfo.BRC_INFO_NO" @click="onClickGds">
            <div class="video-content-group">
              <div class="video-content-title">
                <span class="text video-text-link">
                  {{ getGdsNm(brcGdsInfoList, brcInfo) }}
                </span>
              </div>
              <div class="video-content-price">
                <span v-if="getProductDscntRt(brcInfoDsnList, brcInfo.GDS_ID) > 0" class="price-discount"> {getProductDscntRt( brcInfoDsnList, brcInfo.GDS_ID )} % </span>
                <span class="price-current"> {{ addThousandComma(getGdsUprc(brcGdsInfoList, brcInfo)) + '원' }} </span>
              </div>
            </div>
            <div class="video-content-image">
              <span class="video-image-link">
                <img :src="getGdsImgUrl(brcGdsInfoList, brcInfo)" alt="" @error="handleImgError" loading="lazy" />
              </span>
            </div>
          </button>
        </div>
        <div class="live-title">
          <button type="button" class="live-title-link">
            <span class="text pointer" :data-brcinfo="JSON.stringify(brcInfo)">{{ brcInfo.BRC_TIT }}</span>
          </button>
        </div>
        <div class="live-bottom">
          <div class="bottom-channel">
            <span class="text pointer" :data-brcinfo="JSON.stringify(brcInfo)"> {{ getCtrNm(brcInfo) }} </span>
          </div>
        </div>
      </div>
    </article>
  </a>
</template>

<script>
import errorImg from '@/assets/img/common/error.png';
export default {
	name: 'LiveCommCard',
	props: {
		brcInfo: null,
	},
	data: () => ({
		brcGdsInfoList: [],
		brcInfoDsnList: [],
	}),
	mounted() {
    setTimeout(() => {
      this.addEventListener();
      //console.log('addEventListener');
    }, 1000);
  },
  beforeDestroy() {
	  this.removeEventListener();
  },
  methods: {
	  addEventListener(){
      // 라이브커머스 스와이프 클릭 이벤트 바인딩
      var live = document.querySelectorAll('.fp-live-card');
      for (var i = 0; i < live.length; i++) {
        live[i].addEventListener('click', this.clickMoveLive);
      }
    },
    removeEventListener() {
      // 라이브커머스 스와이프 클릭 이벤트 바인딩
      var live = document.querySelectorAll('.fp-live-card');
      for (var i = 0; i < live.length; i++) {
        live[i].removeEventListener('click', this.clickMoveLive);
      }
    },
    clickMoveLive(e) {
      const brcInfoNo = e.currentTarget.getAttribute('data-brcinfo');
      const brcStsCd = e.currentTarget.getAttribute('data-brcstscd');
      this.$router.push({ name: 'LiveView', params: { brcInfoNo: brcInfoNo }});

      if (parseInt(brcStsCd) < 10) {
        // coming soon
        this.$router.push({ name: 'LiveComing', params: { brcInfoNo: brcInfoNo }});
      } else if (parseInt(brcStsCd) === 20) {
        // vod
        this.$router.push({ name: 'LiveVodView', params: { brcInfoNo: brcInfoNo }});
      } else {
        // live
        this.$router.push({ name: 'LiveView', params: { brcInfoNo: brcInfoNo }});
      }
    },
		onClickBrc(e) {
			const brcInfo = JSON.parse(e.currentTarget.dataset.brcinfo);
			const brcInfoNo = brcInfo.BRC_INFO_NO;
			if (parseInt(brcInfo.BRC_STS_CD) < 10) {
				// coming soon
				this.$router.push({ name: 'LiveComing', params: { brcInfoNo: brcInfoNo }});
			} else if (parseInt(brcInfo.BRC_STS_CD) === 20) {
				// vod
				this.$router.push({ name: 'LiveVodView', params: { brcInfoNo: brcInfoNo }});
			} else {
				// live
				if (brcInfo.VRT_YN === 'N') {
					this.$router.push({ name: 'LiveView', params: { brcInfoNo: brcInfoNo }});
				} else {
					this.$router.push({ name: 'LiveView', params: { brcInfoNo: brcInfoNo }});
				}
			}
		},
		onClickGds(e) {
			const gdsId = e.currentTarget.dataset.gdsid;
			const slArTypCd = e.currentTarget.dataset.slartypcd;
			const brcInfoNo = e.currentTarget.dataset.brcinfono;
			if (gdsId === undefined) {
				const gdsPcomUrl = e.currentTarget.dataset.gdspcomurl;
				window.open(gdsPcomUrl);
			} else {
				let param = {};
				param['gdsId'] = gdsId;
				if (slArTypCd === '300') param['slArTypCd'] = slArTypCd;
				if (brcInfoNo !== 'undefined' && brcInfoNo !== null) {
					param['brcInfoNo'] = brcInfoNo;
				}

				// const url = `/pd/pd/pdpd001?` + qs.stringify(param);
				this.$router.push(url);
			}
		},
		getGdsNm(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			const gdsNm = brcInfo.GDS_NM;
			if (gdsId === null) {
				return gdsNm;
			}
			if (gdsInfoList === undefined || gdsId === null) {
				return gdsNm;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return gdsNm;
			} else {
				return gdsInfo.gdsNm;
			}
		},
		getCtrNm(brcInfo) {
			const suppId = brcInfo.SUPP_ID;
			const ctrId = brcInfo.CTR_ID;
			if (suppId !== null) {
				return brcInfo.SUPP_NM;
			} else if (ctrId !== null) {
				return brcInfo.CTR_NM;
			} else {
				// return SBDC_CTR_NM;
			}
		},
		getProductDscntRt(gdsDsns, gdsId) {
			let dsnRt = 0;
			if (gdsDsns == null || gdsDsns.length === 0) {
				return dsnRt;
			}

			const gdsDsn = gdsDsns.find((slArGdsDsn) => slArGdsDsn.gdsId === gdsId) || {};

			if (gdsDsn['dsnRt'] == null) {
				return dsnRt;
			}

			dsnRt = Math.max(gdsDsn.dsnRt, 0) || dsnRt;
			dsnRt = Math.min(gdsDsn.dsnRt, 100) || dsnRt;

			return dsnRt;
		},
		addThousandComma(number) {
			if (typeof number === 'undefined' || number === null) {
				return 0;
			}
			const numberStr = number.toString();
			if (numberStr.length <= 3) {
				return numberStr;
			} else if (numberStr.substring(0, 1) === '-' && numberStr.length === 4) {
				return numberStr;
			} else {
				let commaNumberStr = '';
				let i;
				for (i = numberStr.length - 3; i > 0; i = i - 3) {
					commaNumberStr = ',' + numberStr.substring(i, i + 3) + commaNumberStr;
				}
				if (i + 3 > 0) {
					commaNumberStr = numberStr.substring(0, i + 3) + commaNumberStr;
				}
				return commaNumberStr; //number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); IE11 및 사파리에서 정상 동작 안함
			}
		},
		getGdsUprc(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			if (gdsId === null) {
				return brcInfo.GDS_PRC;
			}
			if (gdsInfoList === undefined) {
				return brcInfo.GDS_PRC;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return brcInfo.GDS_PRC;
			} else {
				return gdsInfo.gdsUprc;
			}
		},
		getGdsImgUrl(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			if (gdsId === null) {
				return brcInfo.GDS_IMG_URL;
			}

			if (gdsInfoList === undefined) {
				return brcInfo.GDS_IMG_URL;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return brcInfo.GDS_IMG_URL;
			} else {
				return process.env.MINIO_SERVER_BASE_URL + gdsInfo.gdsImgFileUrl.substring(0, gdsInfo.gdsImgFileUrl.lastIndexOf('.')) + GDS_DTL_THUMBNAIL_IMG_SIZE + gdsInfo.gdsImgFileUrl.substring(gdsInfo.gdsImgFileUrl.lastIndexOf('.'));
			}
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
	},
};
</script>
