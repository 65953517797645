<template>
	<full-page-vue :isMobile="isMobile" :pagination="fullpagePagination">
		<!-- s: 동행축제 1. 동행축제배너 -->
		<!-- <main-festa-show @fullpagePagination="handleFullpagePagination" /> -->
		<!-- e: 동행축제 -->

		<!-- s: section 인덱스 1. 온라인 진출이 어려운 소상공인을 돕기 위한 원스톱 지원 솔루션 제공 플랫폼 -->
		<main-index-2024 />
		<!-- e: section 인덱스 -->

		<!-- s: section 인덱스 2. 동영상 배경 인덱스 메인 -->
		<!-- <main-video-index :searchData="searchData" /> -->
		<!-- e: section 인덱스 -->

		<!-- s: 판로TV 3. 판로TV -->
		<main-panro />
		<!-- e: 판로TV -->

		<!-- s: 소담스퀘어 예약 4. 시설예약 -->
		<main-sodam />
		<!-- e: 소담스퀘어 예약 -->

		<!-- s: 라이브홍보 5. 라이브홍보 -->
		<!-- <main-live-pr /> -->
		<!-- s: 라이브홍보 -->

		<!-- s: 온라인 진출 지원사례 6. 온라인 진출 -->
		<!-- <main-online-success /> -->
		<!-- e: 온라인 진출 지원사례 -->

		<!-- s: 마이페이지 7. 마이페이지 -->
		<!-- <main-mypage /> -->
		<!-- e: 마이페이지 -->

		<!-- s:  지원사업 안내 8. 판로지원사업찾기 -->
		<main-biz-support />
		<!-- e:  지원사업 안내 -->

		<!-- s: 온라인 진출 파트너 9. 파트너사  -->
		<!-- <full-page-partner></full-page-partner> -->
		<!-- e: 온라인 진출 파트너 -->

		<!-- s: Fullpage Footer -->
		<main-fullpage-footer />
		<!-- e: Fullpage Footer -->
	</full-page-vue>
</template>
<script>
import { mapGetters } from 'vuex';

import FullPageVue from '@/components/common/FullPageVue';
import FullPagePartner from '@/components/common/FullPagePartner';

import MainFestaShow from '@/components/main/MainFestaShow';
import MainVideoIndex from '@/components/main/MainVideoIndex';
import MainIndex2024 from '@/components/main/MainIndex2024';
import MainSodam from '@/components/main/MainSodam';
import MainPanro from '@/components/main/MainPanro';
import MainBizSupport from '@/components/main/MainBizSupport';
import MainLivePr from '@/components/main/MainLivePr';
import MainOnlineSuccess from '@/components/main/MainOnlineSuccess';
import MainMypage from '@/components/main/MainMypage';
import MainFullpageFooter from '@/components/main/MainFullpageFooter';

export default {
	name: 'Main',
	components: { FullPageVue, MainSodam, FullPagePartner, MainFestaShow, MainIndex2024, MainVideoIndex, MainPanro, MainBizSupport, MainLivePr, MainOnlineSuccess, MainMypage, MainFullpageFooter },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	watch: {},
	props: {
		searchData: Function,
	},
	data: () => ({
		// fullpagePagination: ['동행축제', '메인페이지', '판로TV', '시설 안내/예약', '라이브홍보', '온라인 진출 지원사례', '마이페이지', '지원사업 안내', '온라인 진출 파트너', '공지사항/SNS'],
		// fullpagePagination: ['메인페이지', '판로TV', '시설 안내/예약', '라이브홍보', '온라인 진출 지원사례', '마이페이지', '지원사업 안내', '온라인 진출 파트너', '공지사항/SNS'],
		// fullpagePagination: ['메인페이지', '판로TV', '지원사업 안내', '시설 안내/예약', '온라인 진출 지원사례', '마이페이지', '공지사항/SNS'],
		fullpagePagination: ['메인페이지', '판로TV', '소담스퀘어 예약', '지원사업 안내', '공지사항/SNS'],
	}),
	created() {},
	beforeDestroy() {},
	mounted() {},
	methods: {
		handleFullpagePagination(navi) {
			this.fullpagePagination = navi;
		},
	},
	destroyed() {},
};
</script>
