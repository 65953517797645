<template>
	<section class="fullpage fp-biz __fp-light">
		<div class="fp-content container">
			<h2 class="fp-title">지원사업 안내</h2>
			<p class="fp-subtitle">소상공인에게 딱 맞는 판로 지원사업 찾기</p>
			<div class="fp-biz-wrap">
				<!-- 지원사업 선택 -->
				<nav class="page-nav style-orange">
					<div class="container">
						<ul class="nav-list">
							<li class="nav-item" :class="{ 'is-active': showBizAside }">
								<button type="button" class="button" @click="showBiz(true)">
									<span class="nav-text">중소벤처기업부</span>
								</button>
							</li>
							<li class="nav-item" :class="{ 'is-active': !showBizAside }">
								<button type="button" class="button" @click="getSuptBiz()">
									<span class="nav-text">지방자치단체</span>
								</button>
							</li>
						</ul>
					</div>
				</nav>
				<!-- //지원사업 선택 -->

				<div class="fp-tab-container">
					<div class="fp-tablist" v-if="showBizAside">
						<button type="button" class="fp-tab" @click="showPanPan(panpanCtgr, idx, $event)" v-for="(panpanCtgr, idx) in panpanCtgrList" :key="idx">{{ panpanCtgr.ctgrNm }}</button>
					</div>
					<div class="fp-tabpanels">
						<!-- s: fp-tabpanel -->
						<div class="fp-tabpanel">
							<!-- s : 결과가 있을 경우  -->
							<swiper v-if="showBizAside && mssSprtBizList.length" class="swiper" ref="swiperBiz" :options="swiperOptionBiz">
								<swiper-slide v-for="(mssSprtBiz, idx) in mssSprtBizList" :key="idx">
									<!-- 지원사업 아티클 -->
									<article class="sptpgm-article">
										<header class="sptpgm-article-header justify-content-between">
											<div class="flex align-items-start gap-4">
												<span class="sptpgm-article-header__tag is-gray">{{ mssSprtBiz.sprtBizTyNm }}</span>
												<span class="sptpgm-article-header__tag">{{ mssSprtBiz.sprtBizCtpvNm }}</span>
											</div>
											<span class="sptpgm-article-header__dday">D-{{ mssSprtBiz.dday }}</span>
										</header>
										<div class="sptpgm-article-body">
											<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank">
												<h3 class="sptpgm-article-title">{{ mssSprtBiz.sprtBizNm }}</h3>
												<p class="sptpgm-article-desc">{{ mssSprtBiz.sprtBizPurpose }}</p>
											</a>
										</div>
										<footer class="sptpgm-article-footer">
											<dl class="sptpgm-article-dl">
												<dt><span class="sr-only">기간</span></dt>
												<dd>{{ mssSprtBiz.rcritBgngYmd }} ~ {{ mssSprtBiz.rcritEndYmd }}</dd>
											</dl>
											<!--
												<dl class="sptpgm-article-dl">
													<dt><i class="icon-fp-phone"></i><span class="sr-only">전화</span></dt>
													<dd>02-302-1121</dd>
												</dl>
												-->
											<div class="flex justify-content-end">
												<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank" class="button-default is-primary is-rounded">신청</a>
											</div>
										</footer>
									</article>
									<!-- //지원사업 아티클 -->
								</swiper-slide>
							</swiper>

							<swiper v-else-if="!showBizAside && suptBizList.length" class="swiper" ref="swiperBiz" :options="swiperOptionBiz">
								<swiper-slide v-for="(supt, idx) in suptBizList" :key="idx">
									<!-- 지원사업 아티클 -->
									<article class="sptpgm-article">
										<header class="sptpgm-article-header justify-content-between">
											<div class="flex align-items-start gap-4">
												<span class="sptpgm-article-header__tag ig-gray">{{ supt.pldirSportRealmLclasCodeNm }}</span>
												<span class="sptpgm-article-header__tag" v-text="getAreaInfo(supt.pblancNm)"></span>
											</div>
											<span class="sptpgm-article-header__dday" v-text="getDay(supt.reqstEndDe) <= 0 ? (getDay(supt.reqstEndDe) === 0 ? 'D-Day' : `D+${getDay(supt.reqstEndDe) * -1}`) : `D-${getDay(supt.reqstEndDe)}`"></span>
										</header>
										<div class="sptpgm-article-body">
											<h3 class="sptpgm-article-title">
												<a :href="`https://www.bizinfo.go.kr${supt.pblancUrl}`" target="_blank">{{ supt.pblancNm }}</a>
											</h3>
											<p class="sptpgm-article-desc" v-html="supt.bsnsSumryCn"></p>
										</div>
										<footer class="sptpgm-article-footer">
											<dl class="sptpgm-article-dl">
												<dt><span class="sr-only">기간</span></dt>
												<dd>{{ ymdToFormat(supt.reqstBeginDe, '-') }} ~ {{ ymdToFormat(supt.reqstEndDe, '-') }}</dd>
											</dl>
											<!--											<dl class="sptpgm-article-dl">-->
											<!--												<dt><i class="icon-fp-phone"></i><span class="sr-only">전화</span></dt>-->
											<!--												<dd>02-302-1121</dd>-->
											<!--											</dl>-->
											<div class="flex justify-content-end">
												<a :href="`https://www.bizinfo.go.kr${supt.pblancUrl}`" target="_blank" class="button-default is-primary is-rounded">상세보기</a>
											</div>
										</footer>
									</article>
									<!-- //지원사업 아티클 -->
								</swiper-slide>
							</swiper>
							<!-- <div class="swiper-buttons-container">
									<div class="swiper-button-prev" slot="button-prev"></div>
									<div class="swiper-button-next" slot="button-next"></div>
								</div> -->
							<!-- e : 결과가 있을 경우  -->

							<!-- s : 결과가 없을 경우  -->
							<div class="no-results-found" v-else>
								<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
							</div>
							<!-- e : 결과가 없을 경우  -->

							<div class="fp-tabpanel-buttons">
								<router-link :to="`/supportbiz?showBizAside=${showBizAside}`" class="button-default is-rounded">
									<i class="icon">
										<icon name="icon-plus" />
									</i>
									더보기
								</router-link>
							</div>
						</div>
						<!-- e: fp-tabpanel -->
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { ACT_GET_MSS_SPRT_BIZ_LIST, ACT_GET_SUPT_BIZ_LIST } from '@/store/_act_consts';
import { dateToDateFormat, getCheckItems, getIsNew, getItems, lengthCheck, timestampToDateFormat, ymdToFormat } from '@/assets/js/utils';
import Icon from '@/components/common/Icon';
export default {
	name: 'MainBizSupport',
	components: { Icon },
	data: () => ({
		mssSprtBizList: [], //중소벤처기업부 판로지원사업 목록
		suptBizList: [],
		panpanCtgrList: [
			//중소벤처기업부 지원사업 카테고리 리스트
			{
				id: 0,
				ctgrNm: '전체',
				ctgrCd: '',
			},
			{
				id: 1,
				ctgrNm: '홍보 마케팅',
				ctgrCd: '홍보·마케팅',
			},
			{
				id: 2,
				ctgrNm: '유통 판로',
				ctgrCd: '유통·판로',
			},
			{
				id: 3,
				ctgrNm: '교육 컨설팅',
				ctgrCd: '교육·컨설팅',
			},
			{
				id: 4,
				ctgrNm: '인프라',
				ctgrCd: '인프라',
			},
			{
				id: 5,
				ctgrNm: '기타',
				ctgrCd: '기타',
			},
		],
		showBizAside: true, // 지원사업 안내 사이드 탭 보이기/갑추기
		swiperOptionBiz: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 20,
			navigation: {
				nextEl: '.fp-biz .swiper-button-next',
				prevEl: '.fp-biz .swiper-button-prev',
			},
			breakpoints: {
				200: {
					spaceBetween: 15,
					slidesPerView: 1.2,
				},
				500: {
					spaceBetween: 15,
					slidesPerView: 1.5,
				},
				801: {
					spaceBetween: 20,
					slidesPerView: 2.8,
				},
				1000: {
					spaceBetween: 20,
					slidesPerView: 3,
				},
			},
		},
	}),
	computed: {},
	created() {},
	mounted() {
		this.showBiz(true);
	},
	methods: {
		ymdToFormat,
		dateToDateFormat,
		timestampToDateFormat,

		getIsNew(timestamp, now, baseDay) {
			return getIsNew(timestamp, now, baseDay);
		},
		showBiz(bool) {
			//중소벤처기업부 판로 지원사업
			this.showBizAside = bool;
			this.showPanPan(this.panpanCtgrList[0]);
		},
		getDay: function (reqstEndDe) {
			const reqEndDate = ymdToFormat(reqstEndDe, '-');
			const endDate = new Date(reqEndDate);
			const today = new Date();
			const diffDate = endDate - today;
			return Math.floor(diffDate / (1000 * 3600 * 24)) + 1;
		},
		getAreaInfo(pblancNm) {
			const areaList = ['충북', '충남', '제주', '전북', '전남', '인천', '울산', '세종', '서울', '부산', '대전', '대구', '기타', '광주', '경북', '경남', '경기', '강원'];
			let area = pblancNm.substr(1, 2);
			if (!areaList.includes(area)) area = '전국';
			return area;
		},
		showPanPan(panpanCtgr, idx, event) {
			this.$nextTick(() => {
				idx = !idx ? 0 : idx;

				let tabEl = document.querySelector('.fp-tablist').querySelectorAll('.fp-tab');
				for (let item of tabEl) {
					item.classList.remove('is-active');
				}
				tabEl[idx].classList.add('is-active');

				// api 호출
				this.$store
					.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_LIST}`, {
						pageNo: 1,
						pageSize: 3,
						sprtBizTyNm: panpanCtgr.ctgrCd,
					})
					.then((res) => {
						this.mssSprtBizList = getCheckItems(res);
						//this.$refs.swiperBiz.$swiper.slideTo(0);
					})
					.catch((e) => {
						console.error(e);
					});
			});
		},
		getSuptBiz() {
			// 지자체 지원 사업 탭 클릭 시 해당 func 호출
			// 기존 showBiz(true) 처리를 여기 처리
			this.showBizAside = false;
			// api 호출
			this.$store
				.dispatch(`support/${ACT_GET_SUPT_BIZ_LIST}`, {
					pageNo: 1,
					pageSize: 3,
				})
				.then((res) => {
					if (this.suptBizList.length > 0) this.suptBizList.length = 0;
					this.suptBizList = this.suptBizList.concat(getItems(res));
				})
				.catch((e) => {
					console.error(e);
				});
		},
	},
};
</script>
