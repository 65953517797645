<template>
	<section class="fullpage fp-panro __fp-light" ref="panroTv">
		<div class="fp-content">
			<h2 class="fp-title">판로TV</h2>
			<p class="fp-subtitle">판로TV에서 유익한 판로정보를 확인하세요</p>
			<!-- s: 판로TV 메인 배너 -->
			<div class="broadcast-banner-wrap">
				<nav class="page-nav style-orange">
					<div class="container">
						<ul class="nav-list">
							<li v-for="(item, idx) in tabList" :key="idx" class="nav-item" :class="{ 'is-active': activeIndex === idx }">
								<button type="button" class="button" @click.prevent="gotoSwiper(idx)">
									<span class="nav-text">{{ item }}</span>
								</button>
							</li>
						</ul>
					</div>
				</nav>
				<div class="broadcast-banner">
					<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
						<swiper-slide v-for="(item, idx) in bannerList" :key="idx">
							<a class="swiper-click-event">
								<picture>
									<!-- 
									<source media="(max-width: 1328px)" :srcset="item.moImgPtUrl" />
									<source media="(min-width: 1329x)" :srcset="item.imgPtUrl" /> 
									-->
									<source media="(max-width: 1239px)" :srcset="require(`@/assets/img/renew/${item.moImgFileNm}`)" />
									<source media="(min-width: 1240px)" :srcset="require(`@/assets/img/renew/${item.imgFileNm}`)" />
									<img :src="require(`@/assets/img/renew/${item.imgFileNm}`)" alt="배너 텍스트를 여기에 넣어주세요" />
								</picture>
							</a>
							<!-- <div class="container">
								<toggle-switch :id="item.sortSeq" class="color-orange" :propModel="smsList" :value="item.programCd" label="방송 알림받기" @changefunc="smsListChg(item.programCd)" />
							</div> -->
						</swiper-slide>
					</swiper>
					<!-- 20240516 : 표준 swiper control -->
					<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
				</div>
			</div>
			<!-- e: 판로TV 메인 배너 -->
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import ToggleSwitch from '@/components/common/ToggleSwitch';
import SwiperControls from '@/components/common/SwiperControls';

export default {
	name: 'MainPanro',
	components: { ToggleSwitch, SwiperControls },
	data: () => ({
		activeIndex: 0,
		thisSwiper: null,
		smsList: [], //프로그램코드 별 사용자SMS발송여부 체크박스
		// 라이브홍보 변수
		brcInfoList: [],
		bannerList: [
			{
				imgFileNm: 'banner01.png',
				moImgFileNm: 'banner01_mo.png',
			},
			{
				imgFileNm: 'banner02.png',
				moImgFileNm: 'banner02_mo.png',
			},
			{
				imgFileNm: 'banner03.png',
				moImgFileNm: 'banner03_mo.png',
			},
			{
				imgFileNm: 'banner04.png',
				moImgFileNm: 'banner04_mo.png',
			},
			{
				imgFileNm: 'banner05.png',
				moImgFileNm: 'banner05_mo.png',
			},
			{
				imgFileNm: 'banner06.png',
				moImgFileNm: 'banner06_mo.png',
			},
		],
		tabList: ['판로TV', '나침:판', '판:소리', '성장:판', '메뉴:판', '완:판'],
		swiperOption: {
			loop: false,
			effect: 'fade',
			initialSlide: 0,
			// loopedSlides: 2,
			// notNextTick: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			// loopedSlides: 0,
			spaceBetween: 0,
			touchRatio: 0.2,
			navigation: {
				nextEl: '.broadcast-banner .swiper-button-next',
				prevEl: '.broadcast-banner .swiper-button-prev',
			},

			pagination: {
				el: '.broadcast-banner .swiper-pagination',
				type: 'fraction',
			},
		},
	}),
	watch: {
		// session: function (newVal, oldVal) {
		// 	this.getUserSmsList(newVal.userId);
		// },
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo']),
	},
	created() {},
	beforeDestroy() {
		this.removeEventListener();
	},
	mounted() {
		//this.getUserSmsList(this.session.userId);
		// this.getBannerList(); //판로TV 배너
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});

		// 판로TV, 온라인진출지원사례 클릭 이벤트 바인딩.
		setTimeout(() => {
			this.addEventListener();
			//console.log('addEventListener');
		}, 1000);
		// 판로TV, 온라인진출지원사례 클릭 이벤트 바인딩.
	},
	methods: {
		gotoSwiper(idx) {
			this.thisSwiper.slideTo(idx);
		},
		getUserSmsList(userId) {
			//사용자SMS발송여부 체크박스 초기화
			this.smsList.splice(0);

			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined') {
				this.$store
					.dispatch(`broadcast/${ACT_GET_PROG_SMSUSER_LIST}`, {
						userId: userId,
					})
					.then((res) => {
						var items = getItems(res);
						for (var i = 0; i < items.length; i++) {
							this.smsList.push(items[i].programCd);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		smsListChg(val) {
			if (typeof this.session.userId == 'undefined') {
				alert('로그인이 필요합니다.');
				this.$router.push({ name: 'Login' });
				return false;
			}

			var deleteFlag = false;
			for (var i = 0; i < this.smsList.length; i++) {
				if (val === this.smsList[i]) {
					deleteFlag = true; //체크박스에서 입력된 데이터가 있으면 delete
				}
			}
			if (deleteFlag) {
				//삭제
				this.$store
					.dispatch(`broadcast/${ACT_DELETE_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				//등록
				this.$store
					.dispatch(`broadcast/${ACT_INSERT_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		addEventListener() {
			// 판로TV 스와이프 클릭 이벤트 바인딩
			var brodcast = document.querySelectorAll('.swiper-click-event');
			for (var i = 0; i < brodcast.length; i++) {
				brodcast[i].addEventListener('click', this.clickMoveBrodcast);
			}
		},
		removeEventListener() {
			// 판로TV 스와이프 클릭 이벤트 삭제
			var brodcast = document.querySelectorAll('.swiper-click-event');
			for (var i = 0; i < brodcast.length; i++) {
				brodcast[i].removeEventListener('click', this.clickMoveBrodcast);
			}

			// 온라인 진출 지원사례 스와이프 클릭 이벤트 삭제
			var digit = document.querySelectorAll('.fp-digital-card');
			for (var i = 0; i < digit.length; i++) {
				digit[i].removeEventListener('click', this.clickMoveDigit);
			}
		},
		clickMoveBrodcast() {
			this.$router.push({ name: 'BroadCast' });
		},
		getBannerList() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_PROG_BANNER_LIST}`, {})
				.then((res) => {
					this.bannerList = getItems(res);
				})
				.catch((e) => {
					this.bannerList = [];
				});
		},

		onSwiperChange() {
			this.activeIndex = this.thisSwiper.activeIndex;

			const element = document.querySelectorAll('.broadcast-banner-wrap .page-nav .nav-item')[this.activeIndex];
			if (element) {
				const scrollContainer = document.querySelector('.broadcast-banner-wrap');

				const elementRect = element.getBoundingClientRect();
				const elementWidth = elementRect.width;
				const elementLeft = elementRect.left;
				const scrollContainerWidth = scrollContainer.getBoundingClientRect().width;

				// 요소가 중앙에 위치하도록 스크롤 위치를 계산합니다.
				const scrollLeft = elementLeft + elementWidth / 2 - scrollContainerWidth / 2;

				setTimeout(() => {
					element.parentNode.parentNode.scroll({ left: scrollLeft, behavior: 'smooth' });
				}, 100);
			}
		},
	},
};
</script>
