<template>
	<article class="live-card is-onair">
		<i
			class="video-badge"
			:class="{
				'video-badge-comingsoon': brcInfo.BRC_STS_CD === '07' || brcInfo.BRC_STS_CD === '08' || brcInfo.BRC_STS_CD === '09',
				'video-badge-live': brcInfo.BRC_STS_CD === '10',
				'video-badge-vod': brcInfo.BRC_STS_CD === '20',
			}"
		>
		</i>
		<div tabindex="0" class="pointer" @click="onClickBrc($event)">
			<div class="live-video">
				<div v-if="parseInt(brcInfo.BRC_STS_CD) === 10 || parseInt(brcInfo.BRC_STS_CD) === 20" class="video-watch">
					<i class="icon-watch"></i>
					<span class="text"> {{ brcInfo.INQ_CNT }} </span>
				</div>
				<div class="video-player" ref="video">
					<button type="button" class="video-player-mask" @click="onClickBrc($event)"></button>
					<img v-if="!videoPlay" 
						:src="brcInfo.BRC_RPRS_IMG_URL" 
						alt="" 
						@error="handleImgError" 
					/>
					<iframe v-if="videoPlay && playerUrl != null" 
						:id="'myplayer_' + brcInfo.BRC_INFO_NO" 
						:src="playerUrl + '&controls=0'" 
						allow="autoplay;encrypted-media;fullscreen" 
						allowfullscreen="" 
						webkitallowfullscreen="" 
						mozallowfullscreen="" 
						autoplay
					></iframe>
				</div>
				<button type="button" class="video-content pointer" @click="onClickGds">
					<div class="video-content-group">
						<div class="video-content-title">
							<span class="text video-text-link">{{ brcInfo.GDS_NM }}</span>
						</div>
						<div class="video-content-price">
							<span v-if="getProductDscntRt(brcInfoDsnList, brcInfo.GDS_ID) > 0" class="price-discount"> {getProductDscntRt( brcInfoDsnList, brcInfo.GDS_ID )} % </span>
							<span class="price-current"> {{ addThousandComma(brcInfo.GDS_PRC) + '원' }} </span>
						</div>
					</div>
					<div class="video-content-image">
						<span class="video-image-link">
							<img :src="brcInfo.GDS_IMG_URL" alt="" @error="handleImgError" loading="lazy" />
						</span>
					</div>
				</button>
			</div>
			<div class="live-title">
				<button type="button" class="live-title-link">
					<span class="text pointer">{{ brcInfo.BRC_TIT }}</span>
				</button>
			</div>
			<div class="live-bottom">
				<div class="bottom-channel">
					<span class="text pointer"> {{ getCtrNm(brcInfo) }} </span>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
import { ACT_GET_BRC_INFO } from '@/store/_act_consts';
import errorImg from '@/assets/img/common/error.png';

export default {
	name: 'LiveVideoCard',
	props: {
		brcInfo: Object,
	},
	data: () => ({
		_controller: null,
		videoPlay: false,
		playerUrl: null,

		brcInfoDsnList: [],

		BRC_ATTR: {
			LIVE_COMING: 'LiveComing',
			LIVE_VOD_VIEW: 'LiveVodView',
			LIVE_VIEW: 'LiveView',
		},
	}),
	watch: {
		brcInfo: function (newVal, oldVal) {
			if (this.BRC_ATTR.LIVE_VIEW === this.controlNextByBrcAttr(this.brcInfo)) {
				this.getBrcInfo();
			}
			else {
				this.resetIframeCond();
			}
		},
		videoPlay: function (newVal, oldVal) {
			if (this._controller != null) {
				this._controller.dispose();
				this._controller = null;
			}
			
			const promise = new Promise(async (resolve, reject) => {
				this.renderForcefully();
				if (this.videoPlay) {
					resolve();
				}
			});
			
			promise
				.then(() => this.initVgController())
				.catch(() => {});
		},
	},
	created() {
		if (this._controller != null) {
			this._controller.dispose();
			this._controller = null;
		}
	},
	mounted() {
		this.initializeIntrsObsrv();
	},
	methods: {
		// promise ========================================================================================
		async getBrcInfo() {
			await this.$store
				.dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
					brcInfoNo: this.brcInfo.BRC_INFO_NO,
					brcTyp: 1,
				})
				.then((res) => {
					if ('00' === res.data.RESULT_CD) {
						this.playerUrl = res.data.VIEW.PLAYER_URL[0];
						this.videoPlay = true;
					}
					// return res.data.VIEW;
				})
				// .then((res) => {
				// 	if (res.DP_YN === "Y" && res.VOD_CDN_USE_YN === "Y") {
				// 		// this.initVgController();
				// 	}
				// })
				.catch((err) => {
					console.error(err);
					this.videoPlay = false;
				});
		},

		// html event ========================================================================================
		onClickBrc(e) {
			const _brcInfo = this.brcInfo;
			switch (this.controlNextByBrcAttr(_brcInfo)) {
				case this.BRC_ATTR.LIVE_COMING:
					this.$router.push({ name: this.BRC_ATTR.LIVE_COMING, params: { brcInfoNo: _brcInfo.BRC_INFO_NO } });
					break;
				case this.BRC_ATTR.LIVE_VOD_VIEW:
					this.$router.push({ name: this.BRC_ATTR.LIVE_VOD_VIEW, params: { brcInfoNo: _brcInfo.BRC_INFO_NO } });
					break;
				case this.BRC_ATTR.LIVE_VIEW:
					this.$router.push({ name: this.BRC_ATTR.LIVE_VIEW, params: { brcInfoNo: _brcInfo.BRC_INFO_NO } });
					break;
				default:
					break;
			}
		},
		onClickGds(e) {
			if (this.brcInfo.GDS_ID == null) {
				window.open(this.brcInfo.GDS_PCOM_URL);
			}
		},

		// custome fn ========================================================================================
		getGdsNm(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			const gdsNm = brcInfo.GDS_NM;
			if (gdsId === null) {
				return gdsNm;
			}
			if (gdsInfoList === undefined || gdsId === null) {
				return gdsNm;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return gdsNm;
			} else {
				return gdsInfo.gdsNm;
			}
		},
		getCtrNm(brcInfo) {
			const suppId = brcInfo.SUPP_ID;
			const ctrId = brcInfo.CTR_ID;

			if (suppId != null) {
				return brcInfo.SUPP_NM;
			} else if (ctrId != null) {
				return brcInfo.CTR_NM;
			} else {
				// return SBDC_CTR_NM;
			}
		},
		getProductDscntRt(gdsDsns, gdsId) {
			let dsnRt = 0;
			if (gdsDsns == null || gdsDsns.length === 0) {
				return dsnRt;
			}

			const gdsDsn = gdsDsns.find((slArGdsDsn) => slArGdsDsn.gdsId === gdsId) || {};

			if (gdsDsn['dsnRt'] == null) {
				return dsnRt;
			}

			dsnRt = Math.max(gdsDsn.dsnRt, 0) || dsnRt;
			dsnRt = Math.min(gdsDsn.dsnRt, 100) || dsnRt;

			return dsnRt;
		},
		getGdsUprc(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			if (gdsId === null) {
				return brcInfo.GDS_PRC;
			}
			if (gdsInfoList === undefined) {
				return brcInfo.GDS_PRC;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return brcInfo.GDS_PRC;
			} else {
				return gdsInfo.gdsUprc;
			}
		},
		getGdsImgUrl(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			if (gdsId === null) {
				return brcInfo.GDS_IMG_URL;
			}

			if (gdsInfoList === undefined) {
				return brcInfo.GDS_IMG_URL;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return brcInfo.GDS_IMG_URL;
			} else {
				return process.env.MINIO_SERVER_BASE_URL + gdsInfo.gdsImgFileUrl.substring(0, gdsInfo.gdsImgFileUrl.lastIndexOf('.')) + GDS_DTL_THUMBNAIL_IMG_SIZE + gdsInfo.gdsImgFileUrl.substring(gdsInfo.gdsImgFileUrl.lastIndexOf('.'));
			}
		},

		addThousandComma(number) {
			if (typeof number === 'undefined' || number === null) {
				return 0;
			}
			const numberStr = number.toString();
			if (numberStr.length <= 3) {
				return numberStr;
			} else if (numberStr.substring(0, 1) === '-' && numberStr.length === 4) {
				return numberStr;
			} else {
				let commaNumberStr = '';
				let i;
				for (i = numberStr.length - 3; i > 0; i = i - 3) {
					commaNumberStr = ',' + numberStr.substring(i, i + 3) + commaNumberStr;
				}
				if (i + 3 > 0) {
					commaNumberStr = numberStr.substring(0, i + 3) + commaNumberStr;
				}
				return commaNumberStr; //number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); IE11 및 사파리에서 정상 동작 안함
			}
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
		controlNextByBrcAttr(_brcInfo) {
			// coming soon
			if (parseInt(_brcInfo.BRC_STS_CD) < 10) {
				return this.BRC_ATTR.LIVE_COMING;
			}
			// vod
			else if (parseInt(_brcInfo.BRC_STS_CD) === 20) {
				return this.BRC_ATTR.LIVE_VOD_VIEW;
			}
			// live
			else {
				return this.BRC_ATTR.LIVE_VIEW;
			}
		},

		initializeIntrsObsrv() {
			this.$nextTick(() => {
				const io = new IntersectionObserver((entries, observer) => {
					entries.forEach(async (entry) => {
						if (this.BRC_ATTR.LIVE_VIEW !== this.controlNextByBrcAttr(this.brcInfo)) {
							return;
						}

						// 감지대상이 교차영역에 진입 할 경우
						if (entry.isIntersecting) {
							await this.getBrcInfo();
						} 
						else {
							if (this._controller != null) {
								// this._controller.pause();
								this._controller.dispose();
								this._controller = null;
							}
							this.resetIframeCond();
						}
					});
				});
				io.observe(this.$refs.video);
			});
		},

		initVgController() {
			try {
				// console.log('initVgController Start');
				this._controller = new VgControllerClient({
					target_window: document.getElementById('myplayer_' + this.brcInfo.BRC_INFO_NO).contentWindow,
				});

				this._controller.on('error', (err) => {
					console.log('error: ' + err);
					this.resetIframeCond();
				});

				this._controller.on('pause', () => {
					this._controller.play();
				});

				this._controller.on('ready', () => {
					this._controller.set_controls_activity(false);
					this._controller.set_control_visibility(false); // 내장 Controller 숨기:false, 숨김해제:true
					
					// 크롬정책상 영상 자동재생 전 음소거
					// this._controller.set_volume(50); 	// 볼륨 크기
					this._controller.mute(); // 음소거
					this._controller.play(); // 영상 재생
				});
			} 
			catch (err) {
				console.log(err);
				this.resetIframeCond();
			}
		},

		resetIframeCond() {
			this.playerUrl = null;
			this.videoPlay = false;
		},

		renderForcefully() {
			this.$forceUpdate();
		},
	},
};
</script>
