<template>
	<section class="fullpage fp-index">
		<video autoplay muted loop id="myVideo">
			<source src="~@/assets/img/renew/main_video.webm" type="video/webm" />
			<source src="~@/assets/img/renew/main_video.mp4" type="video/mp4" />
		</video>
		<div class="fp-content container">
			<div class="fp-index-slogan">
				<p class="fp-index-slogan__head">“소상공인 디지털전환이 시작되는 곳”</p>
				<h2 class="fp-index-slogan__title">가치삽시다</h2>
				<p class="fp-index-slogan__desc">소상공인 온라인 진출 지원 플랫폼</p>
			</div>
			<!-- 검색창 -->
			<div class="fp-index-search">
				<fieldset>
					<legend class="sr-only">통합검색</legend>
					<input v-model="searchTerm" type="text" class="input" placeholder="검색어를 입력하세요." @keyup.enter="search" />
					<button type="button" class="fp-index-search__submit" @click="search"><i class="icon-fp-search"></i><span class="sr-only">검색</span></button>
				</fieldset>
			</div>
			<!-- //검색창 -->
			<!-- 검색어 -->
			<div class="fp-index-keywords">
				<router-link v-for="(item, idx) in keywordList" :key="idx" :to="item.url">
					{{ item.text }}
				</router-link>
			</div>
			<!-- //검색어 -->
			<!--  -->
			<div class="fp-index-links">
				<swiper ref="swiperFpLinks" class="swiper" :options="swiperOptionLinks">
					<swiper-slide>
						<a href="javascript:" @click="showBroadcastCalendarModal">
							<span class="icon-fp">
								<i class="icon-fp-link1"></i>
							</span>
							<span class="text">판로TV편성표</span>
						</a>
					</swiper-slide>
					<swiper-slide>
						<router-link to="/education">
							<span class="icon-fp">
								<i class="icon-fp-link2"></i>
							</span>
							<span class="text">e러닝 교육받기</span>
						</router-link>
					</swiper-slide>
					<swiper-slide v-if="isAuth">
						<router-link to="/mypage/reserve">
							<span class="icon-fp">
								<i class="icon-fp-link3"></i>
							</span>
							<span class="text">내 예약현황</span>
						</router-link>
					</swiper-slide>
					<swiper-slide>
						<router-link to="/supportbiz">
							<span class="icon-fp">
								<i class="icon-fp-link4"></i>
							</span>
							<span class="text">지원사업 신청하기</span>
						</router-link>
					</swiper-slide>
					<swiper-slide>
						<a href="https://so-po.stibee.com" target="_blank">
							<span class="icon-fp">
								<i class="icon-fp-link5"></i>
							</span>
							<span class="text">뉴스레터 구독하기</span>
						</a>
					</swiper-slide>
					<swiper-slide>
						<router-link to="/mypage/connect">
							<span class="icon-fp">
								<i class="icon-fp-link6"></i>
							</span>
							<span class="text">문의하기</span>
						</router-link>
					</swiper-slide>
				</swiper>
				<div class="swiper-buttons-container">
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</div>
		<broadcast-calendar-modal v-if="broadcastCalendar.show" />
		<div class="fp-video-control">
			<button type="button" :class="isVideoAutoplay ? 'swiper-button-play' : 'swiper-button-stop'" @click="toggleVideoAutoplay()">
				<template v-if="isVideoAutoplay">재생</template>
				<template v-else>중지</template>
			</button>
		</div>
	</section>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import BroadcastCalendarModal from '@/components/broadcast/BroadcastCalendarModal';

export default {
	name: 'MainVideoIndex',
	components: { BroadcastCalendarModal },
	props: {
		searchData: Function,
	},
	data: () => ({
		isVideoAutoplay: false,
		searchTerm: '',
		keywordList: [
			// {
			// 	url: '/broadcast',
			// 	text: '판로TV 다시보기',
			// },
			// {
			// 	url: '/supportbiz',
			// 	text: '소상공인 지원사업',
			// },
			// {
			// 	url: '/scommunity',
			// 	text: '소상공인 커뮤니티',
			// },
			// {
			// 	url: '/education',
			// 	text: '온라인시장 진출교육',
			// },
			// {
			// 	url: '/playground2?ctgrCode=1012001',
			// 	text: '소담스퀘어 시설예약',
			// },
		],
		swiperOptionLinks: {
			loop: false,
			slidesPerView: 5,
			spaceBetween: 0,
			navigation: {
				nextEl: '.fp-index-links .swiper-button-next',
				prevEl: '.fp-index-links .swiper-button-prev',
			},
			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				340: {
					slidesPerView: 3,
				},
				420: {
					slidesPerView: 4,
				},
				560: {
					slidesPerView: 5,
				},
				// 600: {
				// 	slidesPerView: 6,
				// },
			},
		},
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},
	created() {
		if (this.isAuth) {
			this.swiperOptionLinks.slidesPerView = 6;
			const breakParam = {
				200: { slidesPerView: 2 },
				340: { slidesPerView: 3 },
				420: { slidesPerView: 4 },
				560: { slidesPerView: 5 },
				600: { slidesPerView: 6 },
			};
			this.swiperOptionLinks.breakpoints = breakParam;
		}
	},
	mounted() {},
	methods: {
		search() {
			if (!this.searchTerm) {
				store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '검색어를 입력해주세요.',
				});
			} else {
				this.$router.push({ name: 'Search', query: { searchTerm: this.searchTerm } }).catch(() => {});
				// this.searchData(this.searchTerm);
			}
		},
		showBroadcastCalendarModal() {
			//판로TV 캘린더 modal
			this.$store.commit(`broadcast/${MUT_SHOW_BROADCAST_CALENDAR_MODAL}`, {});
		},
		toggleVideoAutoplay() {
			try {
				if (this.isVideoAutoplay) {
					document.getElementById('myVideo').play();
					this.isVideoAutoplay = false;
				} else {
					document.getElementById('myVideo').pause();
					this.isVideoAutoplay = true;
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>
